<template>
  <tree-transfer
    :title="['待选人员', '已选人员']"
    :from_data="fromData"
    :to_data="toData"
    :defaultProps="{ label: 'text' }"
    @addBtn="add"
    @removeBtn="remove"
    mode="transfer"
    :defaultTransfer="true"
    :default-checked-keys="fromKeys"
    height="400px"
    openAll
  ></tree-transfer>
</template>

<script>
import treeTransfer from "el-tree-transfer";
import _ from "loadsh";
export default {
  name: "index",
  props: {
    defaultData: Array,
  },
  data() {
    return {
      fromData: [],
      toData: [],

      toKeys: [],
      fromKeys: [],
    };
  },
  methods: {
    add(fromData, toData, obj) {
      this.toKeys = _.compact(
        obj.nodes.map((v) => {
          if (v.obj.orgType === 2) {
            return v.id;
          }
        })
      );
      this.$emit("selectPersons", this.toKeys.join(","));
    },
    remove(fromData, toData, obj) {
      _.pullAll(this.toKeys, obj.keys);
      this.$emit("selectPersons", this.toKeys.join(","));
    },
  },
  async mounted() {
    const result = await this.$http({
      url: "/sys/org/dept/tree",
      data: { orgType: "2" },
    });
    this.fromData = result.detail;
    this.fromKeys = this.defaultData;
  },
  components: {
    treeTransfer,
  },
};
</script>

<style scoped></style>
